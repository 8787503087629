import cl from './RecordPage.module.css'
import HeaderUniversal from '../../components/universalComponents/headerUniversal/HeaderUniversal'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import recordStore from '../../store/RecordStore'
import RecordStatusSelect from '../../components/recordPage/recordStatusSelect/RecordStatusSelect'
import ServicesAndPrice from '../../components/recordPage/servicesAndPrice/ServicesAndPrice'
import RecordDate from '../../components/recordPage/recordDate/RecordDate'
import RecordComment from '../../components/recordPage/recordComment/RecordComment'
import CancelRecordButton from '../../components/recordPage/cancelRecordButton/CancelRecordButton'
import RecordPhoneNumber from '../../components/recordPage/recordPhoneNumber/RecordPhoneNumber'
import RecordNote from '../../components/recordPage/recordNote/RecordNote'
import axios from 'axios'

const RecordPage = () => {
    const { id } = useParams()
    const tg = window.Telegram.WebApp;
    const navigate = useNavigate();

    useEffect(() => {
        if (recordStore.recordData.length === 0) {
            navigate('*');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [record, setRecord] = useState(recordStore.recordData.find(record => record.id === parseInt(id)));

    const initialNote = record.note;

    const [note, setNote] = useState(record.note || '');

    const patchRequest = async (navigateTo = null) => {
        if (initialNote !== note) {
            const botUsername = localStorage.getItem('botUsername')

            const patchData = {
                note: note,
            };

            try {
                const response = await axios.patch(`${process.env.REACT_APP_MAIN_API_URL}self-employed/${botUsername}/recordings/${record.id}`, patchData);
                record.note = note;
                console.log("Заметка сохранениа", response.data);
                navigateTo && navigate(navigateTo);
            } catch (error) {
                console.error('Ошибка обновления заметки', error);
            }
        } else {
            navigateTo && navigate(navigateTo);
        }
    }

    useEffect(() => {
        if (!tg.BackButton.isVisible) tg.BackButton.show();
        const handleBackButtonClick = () => {
            patchRequest('*');
        }

        tg.BackButton.onClick(handleBackButtonClick);

        return () => {
            tg.BackButton.offClick(handleBackButtonClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [note]);

    return (
        <div>
            <HeaderUniversal>Запись</HeaderUniversal>

            <RecordStatusSelect record={record} />

            <RecordPhoneNumber phoneNumber={record.phoneNumber} />

            <ServicesAndPrice services={record.services} />

            <RecordDate date={record.date} duration={record.duration} />

            {record.comment &&
                <RecordComment comment={record.comment} />
            }

            <RecordNote note={note} setNote={setNote} patchRequest={patchRequest} />

            {record.status !== 'CANCELED' &&
                <CancelRecordButton id={id} patchRequest={patchRequest} />
            }

        </div>
    )
}

export default RecordPage