// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecordList_noRecordsContainer__jtp7a {
    position: fixed;
    top: 147px;
    left: 0;
    width: 100%;
    height: calc(100% - 147px);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 90;
}

.RecordList_noRecordsMessage__BqOUl {
    color: var(--tg-theme-hint-color);
}`, "",{"version":3,"sources":["webpack://./src/components/recordListPage/recordList/RecordList.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,OAAO;IACP,WAAW;IACX,0BAA0B;;IAE1B,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".noRecordsContainer {\n    position: fixed;\n    top: 147px;\n    left: 0;\n    width: 100%;\n    height: calc(100% - 147px);\n    \n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    z-index: 90;\n}\n\n.noRecordsMessage {\n    color: var(--tg-theme-hint-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noRecordsContainer": `RecordList_noRecordsContainer__jtp7a`,
	"noRecordsMessage": `RecordList_noRecordsMessage__BqOUl`
};
export default ___CSS_LOADER_EXPORT___;
