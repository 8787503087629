import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RecordListPage from './pages/recordListPage/RecordListPage';
import RecordPage from './pages/recordPage/RecordPage';
import CancelRecordPage from './pages/cancelRecordPage/CancelRecordPage';
import ErrorPage from './pages/errorPage/ErrorPage';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = "Appointments";
    if (window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<RecordListPage />} />
          <Route path="/recordPage/:id" element={<RecordPage />} />
          <Route path="/cancelRecord/:id" element={<CancelRecordPage />} />
          <Route path="/errorPage" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
