import { Subheadline1 } from '../../../textStyles/TextStyleComponents'
import cl from './CancelRecordButton.module.css'

const CancelRecordButton = ({ id, patchRequest }) => {

    const handleClickOnCancelButton = () => {
        patchRequest(`/cancelRecord/${id}`);
    }

    return (
        <div className={cl.container} onClick={handleClickOnCancelButton}>
            <Subheadline1 className={cl.text}>Отменить запись</Subheadline1>
        </div>
    )
}

export default CancelRecordButton