import cl from './RecordNote.module.css'
import { Footnote } from '../../../textStyles/TextStyleComponents'
import { useEffect, useRef } from 'react';
import validationStore from '../../../store/ValidationStore'


const RecordNote = ({ note, setNote, patchRequest }) => {
    const tg = window.Telegram.WebApp;
    const textareaRef = useRef(null);

    const adjustHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {            
            textarea.style.height = '0';
            
            const newScrollHeight = textarea.scrollHeight;
            
            textarea.style.height = Math.max(newScrollHeight, 44) + 'px'; 
        }
    };

    useEffect(() => {        
        adjustHeight();
    }, [note]);

    const handleBlur = () => {
        patchRequest();
        tg.isClosingConfirmationEnabled = false;
    };

    const handleFocus = () => {
        tg.isClosingConfirmationEnabled = true;
    }

    return (
        <div className={cl.container}>
            <Footnote className={cl.footnote}>Заметки</Footnote>

            <textarea
                ref={textareaRef}
                className={cl.inpt}
                placeholder={'Запишите личные детали о записи'}
                value={note}
                onChange={(e) => validationStore.handleDescriptionInputChange(e, setNote)}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </div>
    )
}

export default RecordNote