import cl from "./Calendar.module.css"
import { useState, useEffect } from "react";
import { HeadLineSemibold, HeadLineMonoNumbers, Subheadline1 } from "../../../textStyles/TextStyleComponents"
import DayRadio from "./dayRadio/DayRadio";
import { ReactComponent as AddRecordIcon } from '../../../icons/AddRecord.svg';
import selectedDateStore from "../../../store/SelectedDateStore";
import { observer } from "mobx-react-lite";
import axios from "axios";


const Calendar = observer(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentYear = currentDate.getFullYear();

    const tg = window.Telegram.WebApp;

    const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const [monthNumber, setMonthNumber] = useState(currentMonth);
    const [yearNumber, setYearNumber] = useState(currentYear);

    // const [selectedDate, setSelectedDate] = useState({
    //     day: currentDay,
    //     month: currentMonth,
    //     year: currentYear
    //   });

    useEffect(() => {
        const { day, month, year } = selectedDateStore.selectedDate;

        const isSelectedDateEmpty = Object.keys(selectedDateStore.selectedDate).length === 0;
        const isSelectedDateBeforeToday =
            year < currentYear ||
            (year === currentYear && month < currentMonth) ||
            (year === currentYear && month === currentMonth && day < currentDay);

        if (isSelectedDateEmpty || isSelectedDateBeforeToday) {
            selectedDateStore.setSelectedDate({
                day: currentDay,
                month: currentMonth,
                year: currentYear
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDay, selectedDateStore]);


    const getShortDayOfWeek = (year, month, day) => {
        const date = new Date(year, month, day);
        return new Intl.DateTimeFormat('ru-RU', { weekday: 'short' }).format(date).toUpperCase();
    };

    let countOnDisabledDay = 0;

    const generateCalendarData = (startYear, startMonth, startDay, activeCount) => {
        const calendarData = [];
        let year = startYear;
        let month = startMonth;
        let day = startDay;
        let activeDaysGenerated = 0;

        if (!isMobileDevice) {
            let date = new Date(year, month, day);
            let dayOfWeek = date.getDay();
            let daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
            for (let i = daysToMonday; i > 0; i--) {
                countOnDisabledDay++;
                let pastDate = new Date(year, month, day - i);
                calendarData.push({
                    day: pastDate.getDate(),
                    shortDayOfWeek: getShortDayOfWeek(pastDate.getFullYear(), pastDate.getMonth(), pastDate.getDate()),
                    month: pastDate.getMonth(),
                    year: pastDate.getFullYear(),
                    isDisabled: true
                });
            }
        }

        while (activeDaysGenerated < activeCount) {
            const daysInMonth = getDaysInMonth(year, month);
            for (; day <= daysInMonth && activeDaysGenerated < activeCount; day++) {
                const shortDayOfWeek = getShortDayOfWeek(year, month, day);
                calendarData.push({ day, shortDayOfWeek, month, year, isDisabled: false });
                activeDaysGenerated++;
            }
            day = 1;
            month = (month + 1) % 12;
            if (month === 0) year++;
        }

        if (!isMobileDevice) {
            let lastDay = calendarData[calendarData.length - 1];
            let lastDate = new Date(lastDay.year, lastDay.month, lastDay.day);
            let lastDayOfWeek = lastDate.getDay();
            let daysToSunday = lastDayOfWeek === 0 ? 0 : 7 - lastDayOfWeek;
            for (let i = 1; i <= daysToSunday; i++) {
                let nextDate = new Date(lastDay.year, lastDay.month, lastDay.day + i);
                calendarData.push({
                    day: nextDate.getDate(),
                    shortDayOfWeek: getShortDayOfWeek(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate()),
                    month: nextDate.getMonth(),
                    year: nextDate.getFullYear(),
                    isDisabled: true
                });
            }
        }

        return calendarData;
    };

    const calendarData = generateCalendarData(currentYear, currentMonth, currentDay, 60);


    let pixelsForOneDay = 45.5;
    if (isMobileDevice) pixelsForOneDay = 54;

    const countOfDayInThisMonth = (getDaysInMonth(currentYear, currentMonth) - currentDay + 1 + countOnDisabledDay) * pixelsForOneDay;
    const countOfDayInNext1Month = (getDaysInMonth(currentYear, currentMonth + 1)) * pixelsForOneDay + countOfDayInThisMonth;
    const countOfDayInNext2Month = (getDaysInMonth(currentYear, currentMonth + 2)) * pixelsForOneDay + countOfDayInNext1Month;

    let temproraryMonthNumber;
    let temproraryYearNumber = currentYear;

    const scrollSetMonthName = (scrolled) => {
        if (scrolled >= countOfDayInNext2Month) {
            temproraryMonthNumber = currentMonth + 3;
        } else if (scrolled >= countOfDayInNext1Month) {
            temproraryMonthNumber = currentMonth + 2;
        } else if (scrolled >= countOfDayInThisMonth) {
            temproraryMonthNumber = currentMonth + 1;
        } else if (scrolled < countOfDayInThisMonth) {
            temproraryMonthNumber = currentMonth;
        }

        if (temproraryMonthNumber >= 12) {
            temproraryYearNumber = currentYear + 1
            temproraryMonthNumber -= 12
        }

        if (temproraryMonthNumber !== monthNumber) {
            setMonthNumber(temproraryMonthNumber);
        }
        if (temproraryYearNumber !== yearNumber) {
            setYearNumber(temproraryYearNumber);
        }
    }

    const handleSetSelectedDate = (index) => {
        const selectedDateInfo = calendarData[index];
        const newSelectedDate = {
            day: selectedDateInfo.day,
            month: selectedDateInfo.month,
            year: selectedDateInfo.year
        };
        // setSelectedDate(newSelectedDate);
        selectedDateStore.setSelectedDate(newSelectedDate);
    }

    // const handleAddRecord = () => {    
    //     const botLink = `https://t.me/${localStorage.getItem('botUsername')}`;
    //     window.location.href = botLink;
    // }


    const handleOpenWebApp = () => {
        // window.Telegram.WebApp.openTelegramLink('https://test-client-service.gros.pro');
        // window.Telegram.WebApp.openLink('https://test-client-service.gros.pro', { try_instant_view: true });
        const url = new URL('https://test-client-service.gros.pro');
        url.searchParams.append('botUsername', localStorage.getItem('botUsername'));
        url.searchParams.append('actor', 'MASTER');
        url.searchParams.append('clientUserId', localStorage.getItem('botUsername'));

        window.location.href = url.toString();
        // window.open(url.toString(), '_blank');




        // window.location.href = 'https://test-client-service.gros.pro';
    }

    // const handleOpenWebApp = async () => {
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_MASTER_BOT_URL}manage_bot_api/notifier/${localStorage.getItem('botUsername')}/show-client-list`);
    //         console.log('Ответ от Ромы:', response.data);
    //         tg.close();
    //     } catch (error) {
    //         if (error.response) {
    //             // Сервер вернул ответ с кодом ошибки, который находится в пределах диапазона 2xx
    //             const errorData = error.response.data;
    //             console.error("Ошибка Ромы:", errorData.error);
    //             console.error("Описание Ромы:", errorData.description);
    //         } else if (error.request) {
    //             // Запрос был сделан, но ответа не было получено
    //             console.error("Запрос Роме был сделан, но ответа не было получено", error.request);
    //         } else {
    //             // Произошла другая ошибка при настройке запроса
    //             console.error("Ошибка Ромы:", error.message);
    //         }
    //     }
    // };


    return (
        <div>
            <div className={cl.container}>

                <div className={cl.monthYearContainer}>
                    <HeadLineSemibold className={cl.monthText}>{monthNames[monthNumber]}</HeadLineSemibold>
                    <HeadLineMonoNumbers className={cl.yearText}>{yearNumber}</HeadLineMonoNumbers>

                    {/* <a className={cl.btn} href={`https://t.me/${localStorage.getItem('botUsername')}`}>
                        <AddRecordIcon className={cl.icon} />
                        <Subheadline1>Добавить запись</Subheadline1>
                    </a> */}
                    <button className={cl.btn} onClick={handleOpenWebApp}>
                        <AddRecordIcon className={cl.icon} />
                        <Subheadline1>Добавить запись</Subheadline1>
                    </button>
                </div>
                <DayRadio calendarData={calendarData} scrollSetMonthName={scrollSetMonthName} handleSetSelectedDate={handleSetSelectedDate} />
            </div>

        </div>
    )
})

export default Calendar